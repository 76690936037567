/* This example requires Tailwind CSS v2.0+ */
import HeroIconsCheckIcon from "./icons/HeroIconsCheckIcon";
import { useSpring, animated, config } from "react-spring";

const skills = [
  {
    name: "PHP",
    description:
      "This is the language that started it all, and of all the languages I know, this is the one I feel the most comfortable using. I have been writing PHP for more than <span class='text-gray-400'>7 years</span> now.",
  },
  {
    name: "Laravel",
    description:
      "I have been building complex backends, apis, and full fledged SaaS products through Laravel for the past <span class='text-gray-400'>3 years</span> and it has become my weapon of choice when it comes to building website backends.",
  },
  {
    name: "JavaScript",
    description:
      "I have a decent understanding of Javascript, not enough to build my own framework, but enough to get the job done. I have around <span class='text-gray-400'>3 years</span> experience.",
  },
  {
    name: "System Administration",
    description:
      "I know how to and have been maintaining Linux based servers for <span class='text-gray-400'>over 6 years</span> now. I have a firm understanding of the Unix filesystem and POSIX standards.",
  },
  {
    name: "SQL",
    description:
      "I have a decent grasp of the SQL language and have been able to construct moderately complex yet efficient queries in my <span class='text-gray-400'>4 years</span> messing with it. I know how to interface with MySQL, PostgresSQL, and SQLite.",
  },
  {
    name: "Python & Go",
    description:
      "Whether it's web scraping, Discord bots, or even just some rudimentary system management scripts, I find both Python and Go to be super useful. I got into Python about <span class='text-gray-400'>3 years</span> ago and have recently started exploring Go as well.",
  },
];

const SkillsSection = () => {
  const style = useSpring({
    from: { opacity: 0, y: 50 },
    to: { opacity: 1, y: 0 },
    config: config.slow,
    delay: 2800,
  });

  return (
    <animated.div style={style}>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-300">
            Jack of all trades
          </h2>
          <p className="mt-4 text-lg text-gray-500">
            I've worked with and understand a multitude of technologies and
            skills.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
          {skills.map((skill) => (
            <div key={skill.name} className="relative">
              <dt>
                <HeroIconsCheckIcon
                  className="absolute h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-200">
                  {skill.name}
                </p>
              </dt>
              <dd
                className="mt-2 ml-9 text-base text-gray-500"
                dangerouslySetInnerHTML={{ __html: skill.description }}
              />
            </div>
          ))}
        </dl>
      </div>
    </animated.div>
  );
};

export default SkillsSection;
