import { useSpring, animated } from "react-spring";
import { FileIconsLaravel } from "./icons/FileIconsLaravel";
import { FaSolidUserAstronaut } from "./icons/FaSolidUserAstronaut";

const AboutPanel = () => {
  const props = useSpring({
    from: { opacity: 0, y: -50 },
    to: { opacity: 1, y: 0 },
    delay: 1500,
  });

  function getAge(birthday) {
    let today = new Date();
    let birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <animated.div style={props}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 md:mt-20 mt-4">
        <div className="max-w-3xl mx-auto border-2 border-cyan-300 py-4 px-6">
          <div className="flex sm:flex-row flex-col-reverse justify-center">
            <div>
              <p>
                Hey! I'm Sheldon!{" "}
                <FaSolidUserAstronaut className="inline h-6 w-6 mb-1" />
              </p>
              <p className="text-gray-400 mt-2">
                I'm {getAge("10/23/1999")} and I make stuff for the internet.
              </p>
              <p className="text-gray-400 mt-2">
                I write proper code, no bullshit. Proficient in PHP,
                JavaScript, Python and Go.
              </p>
              <p className="text-gray-400 mt-2">
                I have just over 7 years experience in{"  "}
                <a
                  className="text-fuchsia-300 hover:text-fuchsia-400 transition-colors"
                  href="https://laravel.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FileIconsLaravel className="h-4 w-4 mb-1 mr-1 inline" />
                  Laravel
                </a>{" "}
                and PHP and have developed sites used by thousands of users per month. I
                am quick to understand problems at scale.
              </p>
            </div>
            <div className="md:ml-6 md:-mr-1.5 md:mb-0 mb-4">
              <img
                className="rounded-md shadow-md inline w-full border-2 border-cyan-300"
                src="./headshot.png"
                alt="Headshot"
              />
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default AboutPanel;
