import PropTypes from "prop-types";
import classNames from "classnames";

const Button = ({ text, color, fill, href, extraClasses, onClick }) => {
  const classes = classNames(
    "btn-raised",
    "inline-flex",
    "shadow",
    "bg-transparent",
    "border-2",
    "py-2",
    "px-4",
    "mr-10",
    "shadow-md",
    "select-none",
    "duration-200",
    "transition-all",
    "m-0",
    "hover:shadow-sharp",
    "hover:cursor-pointer",
    "transform",
    "active:shadow-sharpest",
    "translate-x-0",
    "translate-y-0",
    "active:translate-x-0",
    "active:translate-y-0",
    "hover:-translate-x-1.5",
    "hover:-translate-y-1.5",
    "hover:ring-black",
    "hover:ring-2",
    color ? `text-${color} border-${color}` : "text-cyan-300 border-cyan-300",
    fill && color && `hover:bg-${color} hover:text-black`,
    extraClasses && extraClasses
  );
  return href ? (
    <a href={href} className={classes}>
      {text}
    </a>
  ) : (
    <button className={classes} onClick={onClick}>
      {text}
    </button>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  fill: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
