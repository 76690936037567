import React from "react";
import Button from "./Button";
import { animated, useSpring } from "react-spring";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

const Navbar = () => {
  let [isOpenContact, setIsOpenContact] = useState(false);

  function closeModal() {
    setIsOpenContact(false);
  }

  function openModal() {
    setIsOpenContact(true);
  }

  const propsLogo = useSpring({
    from: { opacity: 0, y: -80, rotateZ: -50 },
    to: { opacity: 1, y: 0, rotateZ: 0 },
    config: {
      tension: 450,
      mass: 5,
    },
    delay: 500,
  });
  const propsName = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 800,
  });
  const propsResume = useSpring({
    from: { opacity: 0, x: 1000 },
    to: { opacity: 1, x: 0 },
    delay: 800,
  });
  const propsContact = useSpring({
    from: { opacity: 0, x: 1000 },
    to: { opacity: 1, x: 0 },
    delay: 900,
  });

  return (
    <nav className="mx-auto mt-3 max-w-7xl sm:px-6 lg:px-8 md:mt-0">
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div className="flex items-center justify-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <animated.div style={propsLogo}>
              <a href="/">
                <img
                  className="w-auto h-8 sm:h-10"
                  src="./branding/logo-transparent.svg"
                  alt="Logo"
                />
              </a>
            </animated.div>
            <animated.div style={propsName}>
              <img
                className="w-auto h-6 mt-2 ml-4 text-cyan-300"
                src="./branding/sheldon-text.svg"
                alt="Sheldon"
              />
            </animated.div>
          </div>
          <div className="items-center justify-end md:flex md:flex-1 lg:w-0">
            <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
              <animated.div style={propsResume}>
                <Button
                  text="Resumé (EN)"
                  color="green-300"
                  href="/CV-EN.pdf"
                />
              </animated.div>
              <animated.div style={propsResume}>
                <Button text="CV (FR)" color="cyan-300" href="/CV-FR.pdf" />
              </animated.div>
              <animated.div style={propsContact}>
                <Button
                  text="Contact"
                  color="fuchsia-300"
                  fill={true}
                  onClick={openModal}
                />
              </animated.div>
            </div>
            <Transition appear show={isOpenContact} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-gray-800 shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-50"
                      >
                        This is embarrassing... 😬
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          I haven't setup a proper contact form because well...
                          I'm just lazy.
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          Just shoot me an email at{" "}
                          <code className="italic text-gray-300">
                            hello<span className="text-gray-400"> at </span>
                            sheldon.is
                          </code>
                        </p>
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-300 transition-colors bg-gray-700 border border-transparent rounded-md hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                          onClick={closeModal}
                        >
                          Got it, thanks!
                        </button>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
