export function FileIconsLaravel(props) {
  return (
    <svg viewBox="0 0 498 512" {...props}>
      <path
        d="M204.786 56.49L108.402 1.072a8.012 8.012 0 0 0-8.012 0L4.006 56.49C1.314 57.98.036 60.332 0 63.444v329.669a8.05 8.05 0 0 0 4.024 6.955l192.787 110.831c2.786 1.48 5.128 1.45 7.994.019L397.59 400.086a7.99 7.99 0 0 0 4.025-6.955V287.878l92.36-53.104A7.99 7.99 0 0 0 498 227.82V117.924c0-1.985-1.193-5.315-4.098-6.955l-96.384-55.415a8.012 8.012 0 0 0-8.012 0l-96.384 55.415c-2.63 1.497-4.006 3.94-4.006 6.955v105.253l-80.324 46.186V63.443c0-2.42-1.42-5.569-4.006-6.954zm-4.116 327.393l-80.176-45.305l176.579-101.51l80.25 46.13L200.67 383.884zm184.774-205.9v91.38l-80.341-46.186v-91.38l80.341 46.185zm8.031-106.227l80.286 46.168l-80.286 46.167l-80.286-46.167l80.286-46.168zM112.335 324.78V123.502l80.341-46.186v201.259l-80.341 46.204zm-8.03-307.484l80.286 46.167l-80.287 46.168l-80.286-46.168l80.286-46.167zm104.414 472.777v-92.298l176.726-100.74v91.437L208.719 490.072zm192.786-220.709v-91.38l80.342-46.187v91.381l-80.342 46.186zm-208.792 128.41v92.281L15.933 388.471V77.316l80.34 46.186V343.28l96.44 54.494z"
        fill="currentColor"
      />
    </svg>
  );
}
