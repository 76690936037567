import Navbar from "./Navbar";
import AboutPanel from "./AboutPanel";
import ProjectsGrid from "./ProjectsGrid";
import Footer from "./Footer";
import SkillsSection from "./SkillsSection";

const App = () => {
  return (
    <div className="mx-auto px-4">
      <Navbar />
      <AboutPanel />
      <ProjectsGrid />
      <SkillsSection />
      <Footer />
    </div>
  );
};

export default App;
