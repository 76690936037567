import projects from "../projects.json";
import { animated, useSprings, config, useSpring } from "react-spring";

const ProjectsGrid = () => {
  const props = useSpring({
    from: { opacity: 0, y: -50 },
    to: { opacity: 1, y: 0 },
    config: config.molasses,
    delay: 1900,
  });

  const springs = useSprings(
    projects.length,
    projects.map((project) => ({
      ...project.animation,
      config: config.slow,
    }))
  );

  return (
    <animated.div style={props}>
      <div className="text-center mt-20">
        <h1 className="text-gray-200 text-4xl tracking-wide">Projects</h1>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4">
          <div className="max-w-5xl mx-auto py-4">
            <div className="flex justify-between">
              <ul className="grid grid-cols-1 sm:grid-cols-2 gap-8 justify-items-center">
                {springs.map((styles, index) => (
                  <animated.div style={styles} key={index}>
                    <li className="flex flex-col transform transition ease-in-out duration-300 hover:scale-102">
                      <a
                        className="group block w-full relative"
                        href={projects[index].url}
                      >
                        <img
                          src={projects[index].image_url}
                          alt={projects[index].title}
                          className="object-cover pointer-events-none shadow-lg rounded-md group-hover:opacity-75 transition-opacity max-w-full"
                        />
                      </a>
                      <p className="mt-3 block text-sm font-medium truncate pointer-events-none">
                        {projects[index].title}
                      </p>
                      <p className="mt-1 block text-sm font-medium text-gray-500 pointer-events-none">
                        {projects[index].description}
                      </p>
                    </li>
                  </animated.div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default ProjectsGrid;
